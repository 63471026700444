import React, { useEffect, useRef, useState } from 'react';
import {
  Modal, Button, Form, Row, Col,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { RawDateInput, TimeInput } from 'components/FormControls';
import { EnterpriseFormOneTimeEventModelEdit } from 'types/BroadcastForm';
import TextArea from 'components/FormControls/Textarea';

interface OneTimeEventModalProps {
  show: boolean
  onHide: () => void
  onSave: (formData: EnterpriseFormOneTimeEventModelEdit) => Promise<void>
  initialData?: EnterpriseFormOneTimeEventModelEdit
}

const OneTimeEventModal: React.FC<OneTimeEventModalProps> = ({
  show, onHide, onSave, initialData,
}) => {
  const {
    handleSubmit, control, errors, watch,
  } = useForm<EnterpriseFormOneTimeEventModelEdit>({
    defaultValues: initialData || {
      SendDate: '',
      SendTimeLocal: '',
      Message: 'Please complete this survey: $$SURVEY_URL$$',
    },
  });
  const [loading, setLoading] = useState<boolean>(false);
  const isSubmitting = useRef<boolean>(false);
  const maxContentLength = 400;
  const contentLength = watch('Message')?.length ?? 0;

  const onSubmit = async (data: EnterpriseFormOneTimeEventModelEdit) => {
    setLoading(true);
    if (isSubmitting.current) return;
    isSubmitting.current = true;

    try {
      await onSave(data);
      isSubmitting.current = false;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    control.setValue('SendDate', initialData?.SendDate);
    control.setValue('SendTimeLocal', initialData?.SendTimeLocal);
    control.setValue('Message', initialData?.Message);
  }, [control, initialData]);

  let submitButtonText;
  if (loading) {
    submitButtonText = 'Loading...';
  } else {
    submitButtonText = initialData ? 'Update Event' : 'Create Event';
  }

  return (
    <Modal show={show} onHide={onHide} centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>
          {initialData ? 'Update' : 'Create'}
          {' '}
          One-Time Event Generator
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col xs="12">
              <Form.Group>
                <span
                  className="content-length"
                  style={{ float: 'right', padding: '0.3rem' }}
                >
                  {`${contentLength}/${maxContentLength}`}
                </span>
                <TextArea
                  id="Message"
                  name="Message"
                  label="Message"
                  control={control}
                  maxLength={maxContentLength}
                  rules={{
                    required: 'Message is required',
                    maxLength: {
                      value: maxContentLength,
                      message: `Message must be ${maxContentLength} characters or less`,
                    },
                    validate: (val: string) => val.trim() !== '' || 'Message cannot be empty or whitespace',
                  }}
                  errors={errors.Message?.message}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs="12" md="6">
              <Form.Group>
                <RawDateInput
                  id="SendDate"
                  name="SendDate"
                  label="Send Date"
                  control={control}
                  rules={{
                    required: 'Send Date is required',
                  }}
                  errors={errors.SendDate?.message}
                  useSingleMonth
                />
              </Form.Group>
            </Col>
            <Col xs="12" md="6">
              <Form.Group>
                <TimeInput
                  id="SendTimeLocal"
                  name="SendTimeLocal"
                  label="Send Time"
                  control={control}
                  rules={{
                    required: 'Send Time is required',
                  }}
                  errors={errors.SendTimeLocal?.message}
                />
              </Form.Group>
            </Col>
          </Row>

          <div className="d-flex justify-content-end mt-3">
            <Button variant="secondary" onClick={onHide}>
              Cancel
            </Button>
            <Button variant="primary" type="submit" className="ml-2" disabled={loading}>
              {submitButtonText}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default OneTimeEventModal;
